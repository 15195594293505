import { Alerts } from '@corelabs/angular-account/models/alerts';

export const environment = {
    production: false,
    sw: false,
    version: '1.0.0',
    serverUrl: 'https://api.eip-dynamics.sandbox.cloud.codelabs.work/',
    assetsUrl: 'https://api.eip-dynamics.sandbox.cloud.codelabs.work/',
    defaultLanguage: 'pl-PL',
    supportedLanguages: ['pl-PL', 'en-EN'] as const,
    icons: 'assets/svgs/icons.svg',
    table: {
        limit: 12,
    },
    userSwitch: {
        keys: {
            localStorage: 'X-Switch-Customer',
            header: 'X-Switch-Customer',
        },
    },
    addressSwitch: {
        keys: {
            localStorage: 'X-Switch-Shipping-Address',
            header: 'X-Switch-Shipping-Address',
        },
    },
    cacheDurationInMinutes: 10,
    payment: {
        methods: ['transport', 'pickup'] as const,
    },
    productDiscounts: [
        {
            id: 'any',
            value: '',
        },
        {
            id: 'promotions',
            value: 'promotions',
        },
    ],
    productCheckboxCategories: [
        {
            id: 'recently',
            value: '1000',
        },
        {
            id: 'less-frequently',
            value: '1100',
        },
    ],
    isAdmin: false,
    build: {
        time: true,
        commit: true,
    },
    alerts: {
        skip: ['login-invalid-credentials'],
    } as Alerts,
    disabledDates: [new Date('2023-12-23')],
};
